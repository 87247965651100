import FormValidator from '../classes/FormValidator.js';
//static page - pay
if(typeof window.static_pay !== "undefined" && window.static_pay) {
    console.log("static_pay");
    const fields = [
        {
            name: 'numbOrder',
            validations: [
                {
                    type: 'required',
                    errorMessage: '"Номер заказа" обязательно для заполнения'
                }
            ]
        },
        {
            name: 'sumOrder',
            validations: [
                {
                    type: 'required',
                    errorMessage: '"Сумма предоплаты" обязательно для заполнения'
                },
                {
                    type: 'number',
                    errorMessage: 'Сумма предоплаты должна быть числом'
                },
                {
                    type: 'custom',
                    validate: (value) => parseFloat(value) >= 10,
                    errorMessage: 'Сумма предоплаты должна быть больше 10'
                }
            ]
        }
    ];
    const validator = new FormValidator(fields, document.querySelector('#prepayment_form'));

    document.querySelector('#prepayment_form').addEventListener('submit', function(e) {
        if (!validator.validate()) {
            e.preventDefault();
            return false;
        }
        return true;
    });
}

class MarketingClass {
    debug = true;
    brand = "Rosalia Flowers";

    init() {
        window.dataLayer = window.dataLayer || [];
        if (window.product_params && window.product_params.marketing && window.product_params.marketing.price) {
            this.marketing_showProduct(window.product_params.marketing, window.product_params.marketing.price)
        }
    }

    log(msg) {
        if (this.debug) console.log(msg);
    }

    warn(msg) {
        if (this.debug) console.warn(msg);
    }

    check_params(data) {
        if (!data.name || !data.brand || !data.category)
            return false;
        return true;
    }

    /**
     * Получение информации о товаре
     * @param id
     * @returns {*}
     */
    getProductById(id) {
        var $this = this;
        if (typeof id == 'undefined') {
            $this.error('Product.getById(id): id is undefined value');
            return false;
        }
        if (window.App.ajax.product.get_marketing) {
            return fetch(window.App.ajax.product.get_marketing, {
                method: 'POST',
                body: JSON.stringify({
                    "id": id
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
            })
                .then((response) => {
                    // Проверяем успешность запроса и выкидываем ошибку
                    if (!response.ok) {
                        $this.warn(response)
                        throw new Error('Error get api marketing!')
                    }

                    return response.json()
                })
                .catch((err) => {
                    $this.warn(err)
                });
        }
    }

    setShowProductById(id) {
        var $this = this;
        $this.log("setShowProductById");
        if (typeof id == 'undefined' || !id) {
            $this.error('Product.setShowProductById(id): id is undefined value');
            return false;
        }
        if(App.ajax.product.show) {
            fetch(App.ajax.product.show, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN':  document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify({ id: id }) // Тело запроса
            }).catch(error => {
                $this.log('Ошибка при отправке запроса:');
                $this.log(error);
            });
        }
        return true;
    }

    marketing_showProduct(product, price) {
        var $this = this;
        $this.log("marketing_showProduct");
        $this.log(product);

        if (product.id) {
            if ($this.check_params(product)) {
                window.dataLayer.push({
                    "ecommerce": {
                        "detail": {
                            "products": [{
                                "id": product.id,
                                "name": product.name,
                                "price": price,
                                "brand": product.brand,
                                "category": product.category
                            }]
                        }
                    }
                });
            } else {
                $this.log("skip, fetch data");
                $this.getProductById(product.id)
                    .then((data) => {
                            if (data) {
                                product = Object.assign(product, data);
                                if ($this.check_params(product)) {
                                    $this.marketing_showProduct(product, price);
                                }
                            }
                        }
                    )

            }
        }
    }

    marketing_addToCart(products) {
        this.log("marketing_addToCart");
        this.log(products);

        var $this = this;
        if (products instanceof Array) {
            for (let i in products) {
                if (typeof products[i] != 'undefined') {
                    if (products[i].id) {
                        if ($this.check_params(products[i])) {
                            var count_p = 1;
                            if (typeof products[i].quantity != "undefined" && products[i].quantity > 0)
                                count_p = products[i].quantity;
                            window.dataLayer.push({
                                "ecommerce": {
                                    "add": {
                                        "products": [{
                                            "id": products[i].id,
                                            "name": products[i].name,
                                            "price": products[i].price,
                                            "brand": products[i].brand,
                                            "category": products[i].category,
                                            'quantity': count_p,
                                        }]
                                    }
                                }
                            });
                        } else {
                            $this.getProductById(products[i].id)
                                .then((data) => {
                                        if (data) {
                                            let product = Object.assign(products[i], data);
                                            if ($this.check_params(product)) {
                                                $this.marketing_addToCart([product]);
                                            }
                                        }
                                    }
                                )
                        }
                    }
                }
            }
        }
    }

    marketing_removeFromCart(products) {
        this.log("marketing_removeFromCart");
        this.log(products);

        var $this = this;
        if (products instanceof Array) {
            for (let i in products) {
                if (typeof products[i] != 'undefined') {
                    if (products[i].id) {
                        if ($this.check_params(products[i])) {
                            var count_p = 1;
                            if (typeof products[i].quantity != "undefined" && products[i].quantity > 0)
                                count_p = products[i].quantity;
                            window.dataLayer.push({
                                "ecommerce": {
                                    "remove": {
                                        "products": [{
                                            "id": products[i].id,
                                            "name": products[i].name,
                                            "price": products[i].price,
                                            "brand": products[i].brand,
                                            "category": products[i].category,
                                            'quantity': count_p,

                                        }]
                                    }
                                }
                            });
                        } else {
                            $this.getProductById(products[i].id)
                                .then((data) => {
                                        if (data) {
                                            let product = Object.assign(products[i], data);
                                            if ($this.check_params(product)) {
                                                $this.marketing_removeFromCart([product]);
                                            }
                                        }
                                    }
                                )
                        }
                    }
                }
            }
        }
    }

    marketing_createOrder(order_id, products_array) {
        this.log("marketing_createOrder");
        this.log(order_id);
        this.log(products_array);
        var products = [];
        var $this = this;

        for (var i in products_array) {
            var p = products_array[i];
            let pr = {
                'id': p.id,
                'name': p.name,
                'price': p.price,
                'brand': p.brand,
                'category': p.category,
                'quantity': p.count
            };
            if (typeof p.coupon != "undefined" && p.coupon) {
                pr.coupon = p.coupon;
            }
            products.push(pr);
        }

        window.dataLayer.push({
            "ecommerce": {
                "purchase": {
                    "actionField": {
                        "id": order_id
                    },
                    "products": products
                }
            }
        });

    }

    marketing_usePromocode(promocode) {
        this.log("marketing_usePromocode");
        if (window.dashamail) {}
    }
}

window.product_e_commerce = {};
window.MarketingApp = new MarketingClass;
window.MarketingApp.init();

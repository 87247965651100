export default class FormValidator {
    /**
     * Конструктор класса FormValidator.
     * @param {Array} fields - Массив полей для валидации.
     * @param {HTMLElement} parentElement - Родительский элемент, в котором находятся поля (по умолчанию document).
     * @param {Object} options - Дополнительные параметры.
     * @param {boolean} options.disableErrorDisplay - Отключить автоматический вывод ошибок (глобально).
     * @param {string} options.errorClass - Глобальный класс для элемента ошибки (по умолчанию 'inputDecor__error').
     */
    constructor(fields, parentElement = document, options = {}) {
        this.fields = fields;
        this.parentElement = parentElement;
        this.disableErrorDisplay = options.disableErrorDisplay || false; // Глобальное отключение вывода ошибок
        this.globalErrorClass = options.errorClass || 'inputDecor__error'; // Глобальный класс для ошибок
        this.errors = {}; // Объект для хранения ошибок по именам полей
    }

    /**
     * Основной метод валидации.
     * @returns {boolean} - Возвращает true, если все поля валидны, иначе false.
     */
    validate() {
        this.clearErrors(); // Очищаем предыдущие ошибки
        this.errors = {}; // Сбрасываем массив ошибок

        let isValid = true;
        const formData = this.getFormData(); // Получаем данные формы

        this.fields.forEach(field => {
            const element = this.parentElement.querySelector(`[name="${field.name}"]`);
            if (!element) {
                console.error(`Элемент с именем ${field.name} не найден.`);
                isValid = false;
                return;
            }

            const value = element.value.trim(); // Предварительный trim
            const label = this.parentElement.querySelector(`label[for="${element.id}"]`);
            const errorContainer = field.errorContainer || label; // Контейнер для ошибки (по умолчанию label)
            const errorClass = field.errorClass || this.globalErrorClass; // Класс для ошибки (из поля или глобальный)

            // Проверяем условие валидации (если указано)
            if (field.condition && !field.condition(formData)) {
                return; // Пропускаем валидацию, если условие не выполнено
            }

            // Валидация поля
            const fieldErrors = this.validateField(field, value, formData);
            if (fieldErrors.length > 0) {
                isValid = false;
                this.errors[field.name] = fieldErrors; // Сохраняем все ошибки

                // Вывод ошибки, если не отключен глобально и для конкретного поля
                const disableError = field.disableErrorDisplay !== undefined ? field.disableErrorDisplay : this.disableErrorDisplay;
                if (!disableError) {
                    // Выводим только первую ошибку
                    this.displayError(errorContainer, [fieldErrors[0]], errorClass);
                }
            }
        });

        return isValid;
    }

    /**
     * Валидация одиночного поля.
     * @param {Object} field - Поле для валидации.
     * @param {string} value - Значение поля.
     * @param {Object} formData - Данные формы.
     * @returns {Array} - Массив ошибок для поля.
     */
    validateField(field, value, formData) {
        const errors = [];

        if (field.validations) {
            field.validations.forEach(validation => {
                let isValid = true;

                // Проверяем условие валидации (если указано)
                if (validation.condition && !validation.condition(formData)) {
                    return; // Пропускаем валидацию, если условие не выполнено
                }

                // Получаем элемент поля
                const element = this.parentElement.querySelector(`[name="${field.name}"]`);

                // Обработка checkbox
                if (element.type === 'checkbox') {
                    if (validation.type === 'required') {
                        isValid = element.checked; // Проверяем, отмечен ли чекбокс
                    }
                }
                // Обработка radio
                else if (element.type === 'radio') {
                    if (validation.type === 'required') {
                        // Проверяем, выбрана ли хотя бы одна радио-кнопка в группе
                        const radioGroup = this.parentElement.querySelectorAll(`[name="${field.name}"]`);
                        isValid = Array.from(radioGroup).some(radio => radio.checked);
                    }
                }
                // Обработка остальных полей
                else {
                    // Если поле пустое и не обязательное, пропускаем валидацию
                    if (value === '' && validation.type !== 'required') {
                        return;
                    }

                    switch (validation.type) {
                        case 'required':
                            isValid = this.validateRequired(value);
                            break;
                        case 'email':
                            isValid = this.validateEmail(value);
                            break;
                        case 'number':
                            isValid = this.validateNumber(value);
                            break;
                        case 'phone':
                            isValid = this.validatePhone(value);
                            break;
                        case 'regex':
                            isValid = this.validateRegex(value, validation.pattern);
                            break;
                        case 'custom':
                            isValid = validation.validate(value, formData);
                            break;
                        default:
                            console.error(`Тип валидации ${validation.type} не поддерживается.`);
                            isValid = false;
                    }
                }

                if (!isValid) {
                    errors.push(validation.errorMessage || 'Неверное значение.');
                }
            });
        }

        return errors;
    }

    /**
     * Отображение ошибки.
     * @param {HTMLElement} container - Элемент для вывода ошибки.
     * @param {Array} errors - Массив ошибок.
     * @param {string} errorClass - Класс для элемента ошибки.
     */
    displayError(container, errors, errorClass) {
        if (!container) return;

        // Удаляем предыдущие ошибки
        const existingError = container.nextElementSibling;
        if (existingError && existingError.classList.contains(errorClass)) {
            existingError.remove();
        }

        // Добавляем класс ошибки к контейнеру
        if (errors.length > 0) {
            container.classList.add('err');
            const errorElement = document.createElement('div');
            errorElement.className = errorClass; // Используем переданный класс
            errorElement.textContent = errors[0]; // Выводим только первую ошибку
            container.insertAdjacentElement('afterend', errorElement); // Вставляем после контейнера
        } else {
            container.classList.remove('err');
        }
    }

    /**
     * Очистка всех ошибок.
     */
    clearErrors() {
        // Собираем все уникальные классы ошибок
        const errorClasses = new Set();

        // Добавляем глобальный errorClass
        errorClasses.add(this.globalErrorClass);

        // Добавляем errorClass из каждого поля
        this.fields.forEach(field => {
            if (field.errorClass) {
                errorClasses.add(field.errorClass);
            }
        });

        // Удаляем все сообщения об ошибках
        errorClasses.forEach(errorClass => {
            const errorMessages = this.parentElement.querySelectorAll(`.${errorClass}`);
            errorMessages.forEach(error => error.remove());
        });

        // Убираем класс ошибок у всех контейнеров
        const containers = this.parentElement.querySelectorAll('.err');
        containers.forEach(container => container.classList.remove('err'));
    }

    /**
     * Получение всех данных формы в виде объекта.
     * @returns {Object} - Данные формы.
     */
    getFormData() {
        const formData = {};

        // Получаем все элементы формы внутри родительского элемента
        const formElements = this.parentElement.querySelectorAll('input, select, textarea');

        formElements.forEach(element => {
            const name = element.name;
            if (!name) return; // Пропускаем элементы без имени

            // Обрабатываем разные типы элементов
            if (element.type === 'checkbox') {
                formData[name] = element.checked; // Для чекбоксов используем свойство checked
            } else if (element.type === 'radio') {
                if (element.checked) {
                    formData[name] = element.value; // Для радио-кнопок используем значение выбранной кнопки
                }
            } else if (element.tagName === 'SELECT' && element.multiple) {
                // Для множественного выбора (select multiple)
                formData[name] = Array.from(element.selectedOptions).map(option => option.value);
            } else {
                // Для остальных элементов (input, textarea, select)
                formData[name] = element.value.trim();
            }
        });

        return formData;
    }

    /**
     * Валидация обязательного поля.
     * @param {string} value - Значение поля.
     * @returns {boolean} - Результат валидации.
     */
    validateRequired(value) {
        return value.length > 0;
    }

    /**
     * Валидация email.
     * @param {string} email - Значение поля.
     * @returns {boolean} - Результат валидации.
     */
    validateEmail(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    }

    /**
     * Валидация числа.
     * @param {string} number - Значение поля.
     * @returns {boolean} - Результат валидации.
     */
    validateNumber(number) {
        return !isNaN(number) && !isNaN(parseFloat(number));
    }

    /**
     * Валидация телефона.
     * @param {string} phone - Значение поля.
     * @returns {boolean} - Результат валидации.
     */
    validatePhone(phone) {
        const re = /^\+?\d{10,15}$/;
        return re.test(phone);
    }

    /**
     * Валидация по регулярному выражению.
     * @param {string} value - Значение поля.
     * @param {RegExp} pattern - Регулярное выражение.
     * @returns {boolean} - Результат валидации.
     */
    validateRegex(value, pattern) {
        return pattern.test(value);
    }
}
